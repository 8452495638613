<template>
  <div class="deviceFile" :style="{height: resultHeight + 50 + 'px'}">
    <div class="searchBox">
      <Form ref="searchForm" :model="params" inline :label-width="80" class="search">
        <FormItem label="时间段" prop="dateRange" v-if="params.type === 1">
          <ys-date-picker type="datetimerange" clearable v-model="params.dateRange" :disabled="!!modData[0] &&( !!modData[0].signTime || !!modData[0].signOutTime)" placeholder="时间段"></ys-date-picker>
        </FormItem>
        <FormItem label="类型" prop="type">
          <Select v-model="params.type" clearable transfer placeholder="类型" style="width: 100px;">
            <Option v-for="item in [{name: '图片', value: 1}, {name: '视频', value: 2}]" :value="item.value" :key="item.value">{{ item.name }}</Option>
          </Select>
        </FormItem>
      </Form>
      <ButtonGroup class="but">
        <Button type="primary" @click="searchFun">搜索</Button>
        <!-- <Button type="primary" @click="reset">重置</Button> -->
      </ButtonGroup>
    </div>
    <div class="content">
      <Spin class="loading" fix v-if="chartLoading">
        <Icon type="ios-loading" size="35" class="demo-spin-icon-load"></Icon>
        <div>加载中，请稍后......</div>
      </Spin>
      <div style="height: 100%;width: 100%;" v-if="params.type === 1">
        <div v-viewer="{url: 'data-src'}">
          <ys-image 
            :width="115"
            :height="115"
            :src="src.url + '?size=100'"
            :data-src="src.url"
            v-for="src in imageList"
            :key="src.id"
          ></ys-image>
        </div>
      </div>
      <div style="height: 100%;width: 100%;" v-if="params.type === 2">
        <div v-for="(item, index) in video.list" :key="index" style="display: inline-block;text-align: center;">
          <video :src="item.fileName" controls="controls" style="width: 250px;height: 250px;padding: 10px;">
            您的浏览器不支持 video 标签。
          </video>
          <h4>拍摄时间: {{item.createTime}}</h4>
        </div>
      </div>
    </div>
    <div class="footer" v-if="params.type === 2">
      <Page show-total :key="keyIndex" :total="video.total" :page-size="params.limit"
            show-elevator :current="params.page" show-sizer :page-size-opts="[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]" @on-change="pageChange"
            @on-page-size-change="pageSizeChange" placement="top">
      </Page>
    </div>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js'
import {hardHatQueryPhotosBySeqNo, hardHatQueryVideosBySeqNo} from "@/api/base";
import {formatDate} from 'ys-admin'

export default {
  name: '',
  mixins: [pageList],
  data() {
    return {
      params: {
        type: 2,
        seqNo: '',
        dateRange: [],
        startDate: '',
        endDate: '',
        page: 1,
        limit: 10,
      },
      imageList: [],
      video: {
        total: 1,
        list: [
          // {
          //   src: 'https://prod-streaming-video-msn-com.akamaized.net/0b927d99-e38a-4f51-8d1a-598fd4d6ee97/3493c85c-f35a-488f-9a8f-633e747fb141.mp4'
          // },
          // {
          //   src: 'https://prod-streaming-video-msn-com.akamaized.net/bc3e9341-3243-4d2c-8469-940fef56ca2d/4720a02b-eabd-4593-a1d9-5c5d61916853.mp4'
          // },
          // {
          //   src: 'https://prod-streaming-video-msn-com.akamaized.net/0b927d99-e38a-4f51-8d1a-598fd4d6ee97/3493c85c-f35a-488f-9a8f-633e747fb141.mp4'
          // },
          // {
          //   src: 'https://prod-streaming-video-msn-com.akamaized.net/bc3e9341-3243-4d2c-8469-940fef56ca2d/4720a02b-eabd-4593-a1d9-5c5d61916853.mp4'
          // },
          // {
          //   src: 'https://prod-streaming-video-msn-com.akamaized.net/0b927d99-e38a-4f51-8d1a-598fd4d6ee97/3493c85c-f35a-488f-9a8f-633e747fb141.mp4'
          // },
          // {
          //   src: 'https://prod-streaming-video-msn-com.akamaized.net/bc3e9341-3243-4d2c-8469-940fef56ca2d/4720a02b-eabd-4593-a1d9-5c5d61916853.mp4'
          // }
        ]
      },
      keyIndex: 1,
      chartLoading: false,
    }
  },
  mounted() {
    this.params.seqNo = this.midData[0].seqNo || ''
    // 打卡开始结束时间
    const signTime = this.modData[0] && this.modData[0].signTime ? this.modData[0].signTime : ''
    const signOutTime = this.modData[0] && this.modData[0].signOutTime ? this.modData[0].signOutTime : ''

    if(signTime && signOutTime) {
      // 开始结束时间为打卡开始结束时间
      this.params.dateRange = [signTime, signOutTime]
    } else if(signTime && !signOutTime) {
        // 开始时间往后推一天
      this.params.dateRange = [
        signTime,
        formatDate(new Date(new Date(signTime).setDate(new Date().getDate())), 'yyyy-MM-dd hh:mm:ss')
      ]
    } else if(!signTime && signOutTime) {
        // 结束时间往前推一天
      this.params.dateRange = [
        formatDate(new Date(new Date(signOutTime).setDate(new Date().getDate() - 2)), 'yyyy-MM-dd hh:mm:ss'),
        signOutTime
      ]
    } else if(!signTime && !signOutTime) {
      // 现在时间往前推一天
      this.params.dateRange = [
        formatDate(new Date(new Date().setDate(new Date().getDate() - 1)), 'yyyy-MM-dd hh:mm:ss'),
        formatDate(new Date(), 'yyyy-MM-dd hh:mm:ss')
      ]
    }
    this.params.startDate = this.params.dateRange[0] ? this.params.dateRange[0] : ''
    this.params.endDate = this.params.dateRange[1] ? this.params.dateRange[1] : ''
    this.searchFun()
  },
  methods: {
    pageChange(pageIndex) {
      this.params.page = pageIndex;
      this.searchFun();
    },
    pageSizeChange(pageSize) {
      this.params.limit = pageSize;
      this.searchFun();
    },
    async searchFun() {
      if(!this.params.seqNo) return
      this.chartLoading = true
      switch(this.params.type) {
        case 1:
          await hardHatQueryPhotosBySeqNo(this.params).then(res => {
            this.imageList = res.data || []
            if(!res.data) {
              this.$Notice.warning({
                title: '当前时间段内无数据!',
              });
            }
          })
          break;
        case 2:
          await hardHatQueryVideosBySeqNo(this.params).then(res => {
            this.video = res.data
            if(!res.data) {
              this.$Notice.warning({
                title: '当前时间段内无数据!',
              });
            }
          })
          break;
      }
      this.keyIndex = new Date().getTime();
      this.chartLoading = false
    },
    reset() {
      this.$refs.searchForm.resetFields();
      this.searchFun()
      // console.log('reset', this.params);
    },
  },
  watch: {
    'params.dateRange'() {
      this.params.startDate = this.params.dateRange[0] ? this.params.dateRange[0] : ''
      this.params.endDate = this.params.dateRange[1] ? this.params.dateRange[1] : ''
    },
  }
}
</script>

<style lang="less" scoped>
.deviceFile {
  display: flex;
  flex-direction: column;
  .searchBox {
    display: flex;
  }
  .content {
    flex: 1;
    overflow: auto;
    position: relative;
  }
  .footer {
    text-align: right;
  }
}

.loading {
  // background: rgba(0, 0, 0);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 100000;
}

.demo-spin-icon-load {
  animation: ani-demo-spin 1s linear infinite;
}

@keyframes ani-demo-spin {
  from {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>