<template>
  <div>
    <div class="searchBox">
      <Form ref="searchForm" :model="params" inline :label-width="80" class="search">
        <FormItem label="时间段" prop="dateRange">
          <ys-date-picker type="datetimerange" clearable v-model="params.dateRange" :disabled="!!modData[0] &&( !!modData[0].signTime || !!modData[0].signOutTime)" placeholder="时间段"></ys-date-picker>
        </FormItem>
      </Form>
      <ButtonGroup class="but">
        <Button type="primary" @click="searchFun">搜索</Button>
        <!-- <Button type="primary" @click="reset">重置</Button> -->
      </ButtonGroup>
    </div>
    <div :style="{height: resultHeight + 'px', position: 'relative'}">
      <Spin class="loading" fix v-if="chartLoading">
        <Icon type="ios-loading" size="35" class="demo-spin-icon-load"></Icon>
        <div>加载中，请稍后......</div>
      </Spin>
      <div id="mainMap" style="height: 100%;width: 100%;"></div>
    </div>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js'
import {hardHatQueryLocusBySeqNo} from "@/api/base";
import {formatDate} from 'ys-admin'

export default {
  name: '',
  mixins: [pageList],
  data() {
    return {
      params: {
        seqNo: '',
        dateRange: '',
        startDate: '',
        endDate: '',
      },
      chartLoading: false,
      map: null
    }
  },
  mounted() {
    this.params.seqNo = this.midData[0].seqNo || ''
    // 打卡开始结束时间
    const signTime = this.modData[0] && this.modData[0].signTime ? this.modData[0].signTime : ''
    const signOutTime = this.modData[0] && this.modData[0].signOutTime ? this.modData[0].signOutTime : ''

    if(signTime && signOutTime) {
      // 开始结束时间为打卡开始结束时间
      this.params.dateRange = [signTime, signOutTime]
    } else if(signTime && !signOutTime) {
        // 开始时间往后推一天
      this.params.dateRange = [
        signTime,
        formatDate(new Date(new Date(signTime).setDate(new Date().getDate())), 'yyyy-MM-dd hh:mm:ss')
      ]
    } else if(!signTime && signOutTime) {
        // 结束时间往前推一天
      this.params.dateRange = [
        formatDate(new Date(new Date(signOutTime).setDate(new Date().getDate() - 2)), 'yyyy-MM-dd hh:mm:ss'),
        signOutTime
      ]
    } else if(!signTime && !signOutTime) {
      // 现在时间往前推一天
      this.params.dateRange = [
        formatDate(new Date(new Date().setDate(new Date().getDate() - 1)), 'yyyy-MM-dd hh:mm:ss'),
        formatDate(new Date(), 'yyyy-MM-dd hh:mm:ss')
      ]
    }
    this.params.startDate = this.params.dateRange[0] ? this.params.dateRange[0] : ''
    this.params.endDate = this.params.dateRange[1] ? this.params.dateRange[1] : ''

    this.searchFun()
  },
  methods: {
    searchFun() {
      if(!this.params.seqNo) return
      this.chartLoading = true
      hardHatQueryLocusBySeqNo(this.params).then(res => {
        this.$nextTick(() => {
          this.loadTMap(res.data ? res.data : [])
        })
      }).catch(() => {
        this.chartLoading = false
      })
    },
    reset() {
      this.$refs.searchForm.resetFields();
      this.searchFun()
    },
    // 加载腾讯地图
    loadTMap(data = []) {
      if (this.map) this.map.destroy();
      // 数据结构化
      let trailList = []
      this.sortClass(data).map(item => item.map(ite => {
        return [
          ite.coordinate.split(',')[1] * 1,
          ite.coordinate.split(',')[0] * 1,
          // new Date(ite.timeAt).getTime() // 去掉时间戳，则默认以1M/S的单位移动(未设置播放速率时)
        ]
      })).forEach(item => {
        trailList.push({
          path: item
        })  
      })

      // // 平铺后求和求经纬度的平均值
      // const list = trailList.length ? trailList.map(item => item.path).flat() : []
      // let lonCenter = list.length ? eval(list.map(item => item[0]).join("+")) / list.length : 40.040934;
      // let latCenter = list.length ? eval(list.map(item => item[1]).join("+")) / list.length : 116.272677;

      // 初始化腾讯地图
      // eslint-disable-next-line
      this.map = new TMap.Map("mainMap", {
        zoom: 18.5, //设置地图缩放级别
        mapStyleId: 'style 1 墨渊',
        // eslint-disable-next-line
        center: new TMap.LatLng(trailList[0]?.path[0][0] || 40.040934, trailList[0]?.path[0][1] || 116.272677),
        // center: new TMap.LatLng(lonCenter, latCenter),
        baseMap: {
          type: "vector",
          features: ["base", "building3d"], // 隐藏矢量文字
        },
      });
      // 无数据或数据量过少的话值用将地图加载出来即可，轨迹图无需加载
      if (data.length <= 1) {
        this.chartLoading = false
        this.$Notice.warning({
            title: '当前时间段内无数据或数据量过少!',
        });
        return
      }
      // 创建轨迹图
      // eslint-disable-next-line
      var trail = new TMap.visualization.Trail({
        pickStyle: function () { //轨迹图样式映射函数
          return {
            width: 5,
            color: "#c04851",
          };
        },
        showDuration: 100000, //动画中轨迹点高亮的持续时间，以时间戳的差值为准（同通过调整持续显示以及结束时间戳来近似的实现一直显示轨迹）
        playRate: 20, // 动画播放倍速，在原有基础上以20倍的速率播放动画，即若原有是1M/S则20倍为20M/S的速度描绘轨迹
        enableHighlightPoint: true, //是否显示头部高亮点
        startTime: 1, // 动画循环周期的起始时间戳
        endTime: 90000 // 动画循环周期的结束时间戳，稍微比动画持续时间小点要不然的话只有等轨迹完全消失了才会重新描绘
      }).addTo(this.map); // 通过addTo()添加到指定地图实例
      // 设置数据，若需要更新数据则再次调用setData方法即可
      trail.setData(trailList);

      this.chartLoading = false;
    },
    // 数组分组
    sortClass(sortData) {
      const groupBy = (array, f) => {
        let groups = {};
        array.forEach((o) => {
          let group = JSON.stringify(f(o));
          groups[group] = groups[group] || [];
          groups[group].push(o);
        });
        return Object.keys(groups).map((group) => {
          return groups[group];
        });
      };
      const sorted = groupBy(sortData, (item) => {
        return item.timeAt.split(' ')[0]; // 返回需要分组的对象
      });
      return sorted;
    }
  },
  watch: {
    'params.dateRange'() {
      this.params.startDate = this.params.dateRange[0] ? this.params.dateRange[0] : ''
      this.params.endDate = this.params.dateRange[1] ? this.params.dateRange[1] : ''
    },
  }
}
</script>

<style lang="less" scoped>
.searchBox {
  display: flex;
}

.loading {
  // background: rgba(0, 0, 0);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 100000;
}

.demo-spin-icon-load {
  animation: ani-demo-spin 1s linear infinite;
}

@keyframes ani-demo-spin {
  from {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>